import Api from '@/services/Api';

export default {
    fetchUser(userId){
        return Api().get(`/users/${userId}`);
    },

    fetchUsers(query) {
        return Api().get('/users' + query);
    },
}