<template>
  <div v-if="post.vnfd_id" class="header-post">
    <div class="avatar avt-brand" @click="$emit('closeModal')">
      <a
        v-if="post.brand_id && post.brand_id != '0'"
        :href="'/?dispatch=companies.view&company_id=' + post.brand_id + '&tab=style'"
      >
        <img
          width="40"
          height="40"
          v-lazy="{
            src: _companyAvatar(post.company_logo.theme),
            loading: _userDefaultAvatar,
            error: _userDefaultAvatar,
          }"
        />
      </a>
      <router-link v-else :to="{ name: 'profile', query: { profile_id: post.user_id } }">
        <img
          width="40"
          height="40"
          v-lazy="{
            src: _userAvatar(post.avatar),
            loading: _userDefaultAvatar,
            error: _userDefaultAvatar,
          }"
        />
      </router-link>
      <div class="detail-avatar">
        <div class="name">
          <a
            v-if="post.brand_id && post.brand_id != '0'"
            :href="'/?dispatch=companies.view&company_id=' + post.brand_id + '&tab=style'"
          >
            {{ post.company_name }}
          </a>
          <router-link
            v-else
            :to="{ name: 'profile', query: { profile_id: post.user_id } }"
          >
            {{ post.user_name }}
          </router-link>
        </div>
        <div class="time">
          <span>{{ _momentFromNow(post.timestamp) }} </span>
        </div>
      </div>
    </div>
    <div class="brand-new-brand_follow-btn">
      <a
        v-if="post.brand_id && post.brand_id != '0' && post.type == 'm'"
        class="brand_visit-btn primary-button button follow-with-text-btn"
        :href="'/?dispatch=companies.view&company_id=' + post.brand_id + '&tab=style'"
        :title="post.company_name"
        ><i class="dsi dsi-act-store dsi-s16"></i
        ><span class="text-pink-gradient dosiin_ml-2">Ghé cửa hàng</span></a
      >
    </div>
    <div class="btn-group" v-if="show_back_button">
      <button type="button" @click="goBack()">
        <i class="dsi dsi-close"></i>
      </button>
    </div>
    <button class="close-modal" v-if="show_close_button">
      <i class="dsi dsi-close" @click="$emit('closeModal')"></i>
    </button>
  </div>

  <PostHeaderSkeletor v-if="!post.vnfd_id" />
</template>

<script>
import PostHeaderSkeletor from "@/components/skeletons/post/Header";
import { toRefs } from "vue";

export default {
  components: {
    PostHeaderSkeletor,
  },
  props: {
    post: {
      type: Object,
    },
    show_back_button: {
      type: Boolean,
    },
    show_close_button: {
      type: Boolean,
    },
    showSkeletor: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal"],
  setup(props) {
    return {
      ...toRefs(props),
    };
  },
};
</script>
