<template>
    <div class="header-post">
        <div class="avatar">
            <a>
                 <Skeletor circle size="40"/>
            </a>
            <div class="detail-avatar">
                <div class="name">
                    <Skeletor width="50%" pill />
                </div>
                <div class="time">
                    <Skeletor width="30%" pill />
                </div>
            </div>
        </div>
    </div>
</template>