<template>
  <div class="magazine-comment-inner" v-if="loggedIn">
    <form class="magazine-comment-box" method="POST">
      <img v-lazy="_userAvatar(dosiinUser.avatar)" width="36" height="36" />
      <textarea
        name="comment"
        ref="commentForm"
        v-model="content"
        placeholder="Chia sẻ cảm nghỉ của bạn ở đây..."
      ></textarea>
    </form>
    <div class="magazine-submit">
      <button v-on:click="comment" :disabled="isDisabled">
        <pulse-loader v-if="loading" :loading="loading" color="#000"></pulse-loader>
        <span
          >Gửi thảo luận</span
        >
      </button>
    </div>
  </div>
  <div v-else class="no-login">
    <span class="text-pink-gradient dosiin_cursor" @click="login">Đăng nhập </span>
    <span>hoặc </span>
    <router-link
      :to="{
        name: 'register',
        query: { next: encodeURIComponent(currentUrl + postIdUrl) },
      }"
      @click="$emit('closeModal')"
    >
      <span class="text-pink-gradient">Đăng ký</span>
    </router-link>
    <p>Để thoải mái chia sẻ ý kiến đến mọi người</p>
  </div>
</template>

<script>
import DiscussionService from "@/services/discussion";
import { ref, watch, computed, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

export default {
  props: {
    object_id: {
      type: [Number, String],
      require: true,
    },
    modal_detail: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: {},
    },
  },
  emits: ["commentObject"],
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const loggedIn = computed(() => store.state.account.status.loggedIn);
    const dosiinUser = computed(() => store.state.account.user);
    const maxLength = ref(500);
    const content = ref("");
    const isDisabled = ref(false);
    const commentForm = ref(null);
    const route = useRoute();
    const router = useRouter();
    const vnfd_id = props.object_id ? props.object_id : route.params.vid;
    const openModalLogin = inject("openModalLogin");
    const currentUrl = window.location.href;
    const loading = ref(false);
    const postIdUrl = ref("");

    if (props.modal_detail && vnfd_id && Tygh.layout !== 4) {
      postIdUrl.value = "?vnfd_id=" + vnfd_id;
    }

    watch(content, (content, oldContent) => {
      maxLength.value = 500 - content.length;
      if (maxLength <= 0) {
        content.value = oldContent;
      }
    });

    function login() {
      if (!loggedIn.value) {
        if (props.modal_detail) {
          router.push(props.object.seo_name);
        }
        openModalLogin();
      }
    }
    function comment() {
      if (maxLength === 0) {
        toast.warning("Vui lòng nhập nội dung đánh giá bạn nhé");
        return;
      }

      let params = {
        user_id: this.dosiinUser.user_id,
        name: this.dosiinUser.firstname,
        message: this.content,
        object_type: "V",
        object_id: vnfd_id,
        rating_value: 0,
      };

      createComment(params);
    }

    function resize() {
      commentForm.value.style.height = "auto";
      let height = commentForm.value.scrollHeight;
      if (height > 103) {
        height = 103;
      }
      commentForm.value.style.height = height + "px";
    }

    async function createComment(params) {
      loading.value = true;
      let response = await DiscussionService.createDiscussion(params);
      if (response && response.data) {
        content.value = "";
        params.user_avatar = dosiinUser.value.avatar;
        params.timestamp = Math.floor(Date.now() / 1000);
        params.post_id = params.object_id;
        params.like_count = 0;
        emit("commentObject");
        store.commit("PostsStore/PUSH_DISCUSSION_TO_DISCUSSIONS", params);
      }

      loading.value = false;
    }

    return {
      vnfd_id,
      dosiinUser,
      maxLength,
      content,
      loggedIn,
      isDisabled,
      currentUrl,
      loading,
      //refs
      commentForm,
      // methods
      login,
      comment,
      resize,
      createComment,
      postIdUrl,
    };
  },
};
</script>

<style>
/* .black-post {
        padding-bottom: 140px;
    } */

#comment-post {
  max-height: 103px;
  resize: none;
  /* padding: 0px 12px; */
  overflow-y: scroll;
  outline: none;
  font-size: 14px;
  line-height: 150%;
}
.dosiin_action-comment {
  overflow: hidden;
  border-radius: 30px !important;
}
#comment-post::placeholder {
  line-height: 21px;
}
.comment-post::-webkit-scrollbar {
  display: none;
}
.no-login {
  padding: 5px 16px;
}
</style>
