<template>
  <Teleport to="#modals">
    <div class="dosiin_post-detail modal_post-detail">
      <div class="modal dosiin_post-detail" :class="{ open: show }">
        <div class="body-modal box-content-post">
          <div class="post-group overflow black-post">
            <LoadingComponent v-if="state.loading" />
            <template v-else>
              <div class="dosiin_post-img-slider post-img-slider overflow">
                <Swiper
                  :modules="modules"
                  :slideToClickedSlide="swiperOptionsDetail.slideToClickedSlide"
                  :watchSlidesVisibility="swiperOptionsDetail.watchSlidesVisibility"
                  :watchSlidesProgress="swiperOptionsDetail.watchSlidesProgress"
                  :navigation="swiperOptionsDetail.navigation"
                  :preloadImages="false"
                  :lazy="true"
                >
                  <div class="swiper-button-next dosiin_swiper-button"></div>
                  <div class="swiper-button-prev dosiin_swiper-button"></div>
                  <SwiperSlide class="img-post-wrapper dosiin_post-detail">
                    <img v-lazy="_postImage(postDetail.main_pair)" class="dosiin_w-100" />
                  </SwiperSlide>
                  <SwiperSlide
                    class="img-post-wrapper dosiin_post-detail"
                    v-for="(img, i) in postDetail.addition_images"
                    :key="i"
                  >
                    <img v-lazy="_postImageWithFullUrl(img)" class="dosiin_w-100" />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div class="post-group-info">
                <PostHeader
                  :post="postDetail"
                  :show_back_button="false"
                  :show_close_button="true"
                  @close-modal="closeModal"
                />
                <div class="scroll-bar-wrap">
                  <div class="scroll-box">
                    <div class="content">
                      <div class="tag-link" v-if="postDetail.tags">
                        <router-link
                          v-for="(tag, key) in postDetail.tags"
                          :to="{ name: 'tags', query: { tag: tag.tag } }"
                          v-bind:key="key"
                          @click="closeModal"
                          >#{{ $filters.trim(tag.tag) }}&nbsp;
                        </router-link>
                      </div>

                      <section id="firstContent">
                        <div class="desc dosiin_mb-16" v-text="postDetail.short"></div>
                      </section>

                      <div class="box-product-tag">
                        <div
                          class="list-item product-list-item"
                          v-for="(product, p_id) in postDetail.products"
                          v-bind:key="p_id"
                        >
                          <a
                            class="list-item_img"
                            :href="product.seo_name"
                            :title="product.product"
                          >
                            <img
                              class="product-sm-item_img"
                              v-lazy="_productThumbnail(product.main_pair)"
                              :alt="product.product"
                              :title="product.product"
                              width="76"
                              height="76"
                            />
                          </a>
                          <dl class="list-item_summary">
                            <div class="product-list-item_name_wrapper">
                              <dd class="list-item_name">
                                <a
                                  class="list-item_product"
                                  :href="product.seo_name"
                                  :title="product.product"
                                >
                                  {{ product.product }}</a
                                >
                              </dd>
                            </div>
                            <div class="list-item_sale product-list-item_price_wrapper">
                              <dd class="list-item_price">
                                {{ numberFormat(product.price) }}đ
                              </dd>
                              <dd
                                class="inline-bl list-item_sale_origin"
                                v-if="product.list_price > product.price"
                              >
                                {{ numberFormat(product.list_price) }}đ
                              </dd>
                              <dd
                                class="inline-bl list-item_sale_discount"
                                v-if="discountPrc(product) > 0"
                              >
                                {{ discountPrc(product) }}%
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>

                      <div v-if="postDetail.categories_brands">
                        <div class="dosiin-tag-properties">
                          <dl
                            class="tag-list-item"
                            v-for="(
                              cat_with_name, index
                            ) in postDetail.categories_brands_with_name"
                            v-bind:key="index"
                          >
                            <dt class="tag-icon dosiin_mr-2">
                              <i :class="['dsi', getCategoryIcon(index)]"></i>
                            </dt>
                            <dd class="tag-name">
                              <div
                                class="dosiin_hashtag_slider swiper-tag-name-detail-feed"
                              >
                                <div class="swiper-wrapper">
                                  <div
                                    class="swiper-slide"
                                    v-for="(brand_name, i) in cat_with_name.brand_name"
                                    :key="i"
                                  >
                                    <router-link
                                      :to="{ name: 'tags', query: { tag: brand_name } }"
                                      :title="brand_name"
                                      @click="closeModal"
                                    >
                                      <data class="tag-name-item dosiin_mr-1"
                                        >#{{ brand_name }}</data
                                      >
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>

                      <div class="section-wrappers dosiin-comment-feed-detail-warpper">
                        <div class="section-title-wrapper dosiin_mt-2">
                          <h2 class="section-title">Thảo luận bài viết</h2>
                        </div>

                        <template v-if="discussions.posts && discussions.posts.length">
                          <div class="section-title-wrapper">
                            <h2 class="section-title1">
                              <b>{{ postDetail.discussion_count }} </b>
                              <span> thảo luận</span>
                            </h2>
                            <button
                              class="dosiin_comment-sort comment-sort-btn d-flex align-items-center justify-content-between d-none"
                            >
                              Mới - Cũ<i class="dsi dsi-arrow-down dosiin_ml-2"></i>-
                            </button>
                          </div>

                          <div
                            class="comment-feed-detail-warpper customer-review-section dosiin_pt-0 dosiin_pl-0 dosiin_pr-0"
                          >
                            <div
                              class="review-item review-item-product list-comment-feed-detail"
                              v-for="(discussion, i) in discussions.posts"
                              :key="i"
                            >
                              <div class="review-item-info">
                                <router-link
                                  :to="{
                                    name: 'profile',
                                    query: { profile_id: discussion.user_id },
                                  }"
                                  class="link-item-product"
                                >
                                  <img
                                    v-lazy="_userAvatar(discussion.user_avatar)"
                                    class="user-avatar"
                                    width="40"
                                    height="40"
                                  />
                                </router-link>
                                <div class="rating-info">
                                  <h3
                                    class="user-name truncate"
                                    v-text="discussion.name"
                                  ></h3>
                                  <div class="rating-with-time">
                                    <span
                                      class="rating-time-product"
                                      v-text="_momentFromNow(discussion.timestamp)"
                                    ></span>
                                  </div>
                                  <div class="review-item-preview dosiin_mt-2">
                                    <div class="review-item-preview_left">
                                      <div class="review-item-description">
                                        <span v-text="discussion.message"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="review-item-action d-none">
                                    <div class="review-item-action-btn">
                                      <LikeButton
                                        likeable_type="discussion"
                                        :likeable_id="discussion.post_id"
                                        :isLiked="discussion.is_like"
                                        :object="discussion"
                                      />

                                      <button
                                        class="more-btn dosiin_reply-btn comment"
                                        @click="
                                          $notify({
                                            type: 'vv',
                                            title: 'Thông báo',
                                            text: 'Tính năng đang phát triển.',
                                          })
                                        "
                                      >
                                        <i class="dsi dsi-more-h"></i>
                                      </button>
                                    </div>
                                    <div class="review-item-action-info">
                                      <span class="info-like"
                                        >{{ discussion.like_count }} lượt thích</span
                                      >
                                      <!-- <span class="info-reply">0 phản hồi</span> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>

                        <div class="no_comments-inner" v-else>
                          <div class="no_comments">
                            <img :src="_emptyReview" />
                            <p>Bài viết chưa có thảo luận</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="link-group-action" id="dosiin_footer-sticky">
                  <CommentPost
                    :object_id="postDetail.vnfd_id"
                    :object="postDetail"
                    :modal_detail="true"
                    @close-modal="closeModal"
                    @commentObject="
                      () =>
                        (postDetail.discussion_count =
                          JSON.parse(postDetail.discussion_count) + 1)
                    "
                  />
                  <hr class="line" />
                  <div class="review-item-action align-items-center dosiin_pd-016">
                    <div class="review-item-action-btn">
                      <LikeButton
                        likeable_type="vnfd"
                        objectType="vnfd"
                        :likeable_id="postDetail.vnfd_id"
                        :isLiked="postDetail.is_like"
                        :object="postDetail"
                        :indexPost="'post_' + postDetail.vnfd_id"
                        :modal_detail="true"
                        @likeObject="
                          () => (postDetail.like_count = postDetail.like_count + 1)
                        "
                        @unlikeObject="
                          () => (postDetail.like_count = postDetail.like_count - 1)
                        "
                      />

                      <button
                        class="share_dropdow dosiin_ml-2"
                        @click="sharePostDropdown(postDetail, $event)"
                      >
                        <i class="dsi dsi-share"></i>
                      </button>
                    </div>
                    <div class="review-item-action-info">
                      <span class="info-like"
                        ><b>{{ postDetail.like_count }} </b>&nbsp;lượt thích</span
                      >
                      <span class="dosiin_mr-2 dosiin_ml-2"> • </span>
                      <span class="info-reply dosiin_ml-0"
                        ><b>{{ postDetail.discussion_count }} </b>&nbsp;bình luận</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <ShareButtonDropdown ref="dropdownShare" />
      </div>
    </div>
  </Teleport>
</template>

<script>
import PostHeader from "@/components/post/Header.vue";
import CommentPost from "@/components/forms/CommentForm.vue";
import StyleService from "@/services/style";
import DiscussionService from "@/services/discussion";
import { Navigation, Lazy } from "swiper";
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  components: {
    CommentPost,
    PostHeader
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    onMounted(() => {
      store.dispatch("modal/initModal");
    });

    const swiperOptionsDetail = {
      slideToClickedSlide: true,
      watchSlidesVisibility: false,
      watchSlidesProgress: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    const state = reactive({
      loading: false,
    });

    const getDiscussionStore = store.getters["PostsStore/getDiscussion"];
    let discussions = computed(() => getDiscussionStore(postDetail.value.vnfd_id));
    const loading = ref(true);
    const postDetail = ref({});
    const originUrl = window.location.origin;
    const show = ref(false);

    function detailPost(post) {
      state.loading = true;
      postDetail.value = post;
      show.value = true;
      store.dispatch("modal/increase");

      getPostDetail(postDetail.value.vnfd_id);

      setTimeout(() => {
        state.loading = false;
      }, 300);
    }

    const closeModal = () => {
      show.value = false;
      store.dispatch("modal/decrease");
    };

    // methods
    async function getPostDetail(vnfd_id) {
      const response = await StyleService.fetchPost(vnfd_id, {
        timestamp: new Date().getTime(),
      });

      let paramsDiscussion = {
        vnfd_id: vnfd_id,
        params: {
          object_id: vnfd_id,
          type: "V",
          timestamp: new Date().getTime(),
        },
      };
      getDiscussion(paramsDiscussion);
      store.dispatch("PostsStore/addPosts", { postData: [response.data] });
      return response.data;
    }

    async function getDiscussion({ params, vnfd_id }) {
      const response = await DiscussionService.fetchPostDiscussion(vnfd_id, params);
      store.commit("PostsStore/PUSH_DATA_DISCUSSIONS", { data: response.data, vnfd_id });
    }

    watch(
      () => route.name,
      () => {
        closeModal();
      }
    );

    return {
      state,
      show,
      detailPost,
      loading,
      originUrl,
      postDetail,
      discussions,
      swiperOptionsDetail,
      modules: [Navigation, Lazy],
      closeModal,
    };
  },
  methods: {
    sortDiscussion(event, vnfd_id) {
      let button = event.target;
      let sort_order = "desc";

      if (button.classList.contains("dsi-drop-down")) {
        sort_order = "desc";
        button.classList.remove("dsi-drop-down");
        button.classList.add("dsi-drop-up");
      } else {
        sort_order = "asc";
        button.classList.remove("dsi-drop-up");
        button.classList.add("dsi-drop-down");
      }

      this.CHANGE_DISCUSSION_SORT({
        sort_by: "timestamp",
        sort_order: sort_order,
        vnfd_id: this.vnfd_id,
      });
    },
    sharePostDropdown(post, event) {
      const modal_post = true;
      this.$refs.dropdownShare.updatePost(post, event, modal_post);
    },
  },
};
</script>

<style>
.desc img {
  width: 100% !important;
  height: auto;
}

.news-title {
  font-weight: 700;
  font-size: 1.6em;
  line-height: 1.2em;
  margin: 0;
  padding-top: 30px;
  margin-bottom: 1.2rem;
}

#firstContent {
  clear: both;
  display: block;
  visibility: visible;
  opacity: 1;
  position: relative;
  line-height: 30px;
}

.dosiin_post-detail {
  left: 0;
}

.dosiin_post-detail .swiper,
.dosiin_post-detail .dosiin_post-img-slider .swiper-wrapper {
  z-index: 0 !important;
  height: 100%;
}

.scroll-bar-wrap .scroll-box {
  padding-bottom: 90px;
}
</style>
