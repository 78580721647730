<template>
    <div class="tag-link" v-if="post.tags"> 
        <router-link 
            v-for="(tag ,key) in post.tags" 
            :to="{ name: 'tags', query: { tag: tag.tag }}"
            v-bind:key="key"
        >#{{ tag.tag }}&nbsp;
        </router-link>
    </div>
</template>

<script>
    export default {
        props:{
            post:Object
        }
    }
</script>
