<template>
  <div
    class="dosiin-tag-feed d-flex align-items-center justify-content-between"
    v-if="post && post.categories_brands_with_name"
  >
    <a
      class="tag-feed-btn dosiin_bs-modal-tag-feed d-flex align-items-center justify-content-between dosiin_mr-2"
      title="Hashtag"
      @click="tagPost(post)"
      v-if="Object.keys(post.categories_brands_with_name).length > 0"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.18832 2.78866C9.47476 2.49609 9.86421 2.33203 10.2692 2.33203H13.4159C14.1014 2.33203 14.6662 2.90816 14.6666 3.60728V6.81755C14.6666 7.23076 14.5054 7.6274 14.219 7.91956L9.5866 12.6447C8.99336 13.2499 8.01858 13.2499 7.42533 12.6447L7.3944 12.6132L6.80837 13.2115C6.21512 13.8166 5.24035 13.8166 4.6471 13.2115L1.77825 10.2846C1.185 9.67951 1.185 8.68575 1.77825 8.08062L6.41008 3.35544C6.69653 3.06287 7.08598 2.89881 7.49099 2.89881H9.08034L9.18832 2.78866ZM8.24687 3.74898H7.49099C7.30646 3.74898 7.13021 3.82346 6.99992 3.95654L2.36754 8.68172C2.09286 8.9619 2.09286 9.40337 2.36754 9.68354L5.23693 12.6099C5.51161 12.89 5.94441 12.89 6.21908 12.6099L6.80511 12.0121L4.55648 9.71786C3.96323 9.11273 3.96323 8.11898 4.55648 7.51385L8.24687 3.74898ZM12.1657 4.03237C11.9446 4.03237 11.7326 4.12194 11.5763 4.28138C11.42 4.44081 11.3322 4.65706 11.3322 4.88253C11.3322 5.10801 11.42 5.32426 11.5763 5.48369C11.7326 5.64313 11.9446 5.7327 12.1657 5.7327C12.3867 5.7327 12.5987 5.64313 12.755 5.48369C12.9113 5.32426 12.9992 5.10801 12.9992 4.88253C12.9992 4.65706 12.9113 4.44081 12.755 4.28138C12.5987 4.12194 12.3867 4.03237 12.1657 4.03237Z"
          fill="#E6E6E6"
        ></path>
      </svg>
      <span
        class="dosiin_ml-1"
        v-text="Object.keys(post.categories_brands_with_name).length"
      ></span>
    </a>
    <a
      class="tag-feed-btn dosiin_bs-modal-tag-feed d-flex align-items-center justify-content-between"
      title="Hastag"
      v-if="post.products && Object.keys(post.products).length > 0"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.43718 1.96278H10.6486C11.0313 1.96278 11.3987 2.11362 11.67 2.38281C11.9414 2.65207 12.0942 3.01773 12.0942 3.39949V5.90496H12.7343V3.39949C12.7343 2.85188 12.5151 2.32625 12.1242 1.93835C11.7332 1.55038 11.2024 1.33203 10.6486 1.33203H3.58574C3.03186 1.33203 2.5011 1.55038 2.11011 1.93835C1.7192 2.32625 1.5 2.85188 1.5 3.39949V10.4078C1.5 10.9554 1.71921 11.4811 2.11011 11.8689C2.5011 12.2569 3.03186 12.4753 3.58574 12.4753H5.00934V11.8445H3.58574C3.20304 11.8445 2.83557 11.6937 2.56428 11.4245C2.29292 11.1552 2.14007 10.7895 2.14007 10.4078V3.39949C2.14007 3.01773 2.29292 2.65207 2.56428 2.38281C2.83556 2.11362 3.20302 1.96278 3.58574 1.96278H6.79711V4.37189H7.43718V1.96278ZM13.2971 7.90233H8.44143C8.1217 7.90233 7.81549 8.0284 7.59007 8.25208C7.36473 8.47568 7.23854 8.77847 7.23854 9.09375V13.4739C7.23854 13.7892 7.36473 14.092 7.59007 14.3156C7.81549 14.5393 8.1217 14.6654 8.44143 14.6654H13.2971C13.6168 14.6654 13.923 14.5393 14.1485 14.3156C14.3738 14.092 14.5 13.7892 14.5 13.4739V9.09375C14.5 8.77847 14.3738 8.47568 14.1485 8.25208C13.923 8.02839 13.6168 7.90233 13.2971 7.90233ZM11.1401 10.5042V8.53308H10.5V10.5042H11.1401Z"
          fill="#E6E6E6"
        ></path>
      </svg>
      <span class="dosiin_ml-1" v-text="Object.keys(post.products).length"></span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    post: Object,
  },
  emits: ["showTags"],
  setup(props, { emit }) {
    const tagPost = (post) => {
      emit("showTags", post);
    };

    return {
      tagPost,
    };
  },
};
</script>
