import Api from '@/services/Api';

export default {
    fetchTags(query) {
        return Api().get('/tags' + query);
    },

    fetchGroupTags(queryString){
        return Api().get('/tags?getGroup=true&count_tag=true' + queryString);
    },

    fetchGroupTagsWithPost(id){
        if(id){ 
            return Api().get(`/tags/${id}?getGroup=true&count_tag=true&getPostGroup=true`);
        }
        
        return Api().get(`/tags?getGroup=true&count_tag=true&getPostGroup=true`);
    }
}