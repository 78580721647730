import Api from '@/services/Api';
export default {
  fetchDiscussions(id, type) {
    return Api().get(`4.0/discussions/${id}?type=${type}`);
  },

  createDiscussion(params) {
    return Api().post('/discussions', params);
  },

  createDiscussionWithImage(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    return Api().post('/discussions', formData, {
      headers: {
        'Authorization': 'Bearer ' + user.accessToken,
        "Content-Type": "multipart/form-data"
      }
    });
  },

  fetchPostDiscussion(id, params) {
    return Api().get(`4.0/discussions/${id}`, { params });
  }
}